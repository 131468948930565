@font-face {
  font-family: "iconfont";
  src: url("../icons/iconfont.ttf") format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconleft:before {
  content: "\e635";
}

.iconright:before {
  content: "\e636";
}

.iconyoutube:before {
  content: "\e7b2";
}

.iconclose:before {
  content: "\e603";
}

.iconmenu:before {
  content: "\e601";
}

.iconminus:before {
  content: "\e631";
}

.iconplus:before {
  content: "\e632";
}

.iconfacebook:before {
  content: "\e62d";
}

.iconlinkedIn:before {
  content: "\e62e";
}

.iconmedium:before {
  content: "\e62f";
}

.icontwitter:before {
  content: "\e630";
}
.iconinstagram:before {
  content: "\e88f";
}
.icontelegram:before {
  content: "\e627";
}