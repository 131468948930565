@import "./reset";
@import "./iconfont";

// custom
* {
  box-sizing: border-box;
}
html {
  width: 100%;
  // height: 100%;
  // height: auto;
  // min-width: 1260px;
  min-height: 768px;
}
body {
  font-family: "CASaygonText", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, "Segoe UI", Arial, Roboto, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
;
  // height: 100%;
}
#root {
  height: 100%;
}
img {
  display: block;
  outline: none;
}
// 去除超链接所有默认样式
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
  backface-visibility: hidden;
}
li {
  list-style: none;
}
.row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.row-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.row-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.col-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.col-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
@font-face {
  font-family: "CASaygonText-Light";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/CASaygonText-Light.ttf") format("opentype");
}
@font-face {
  font-family: "CASaygonText-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/CASaygonText-Regular.otf") format("opentype");
}
@font-face {
  font-family: "CASaygonText-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/CASaygonText-Medium.otf") format("opentype");
}
@font-face {
  font-family: "CASaygonText-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/CASaygonText-Bold.otf") format("opentype");
} ;
